export const dev = {
    awsConfig: {
        "aws_project_region": "ap-southeast-2",
        "aws_cognito_region": "ap-southeast-2",
        "aws_user_pools_id": "ap-southeast-2_TgQHONcxR",
        "aws_user_pools_web_client_id": "7k3lkiajr5hel9emgl5fjhisp0",
        "oauth": {
            domain: 'auth.dev.halter.io',
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://farmhand.dev.halter.io',
            redirectSignOut: 'https://farmhand.dev.halter.io',
            responseType: 'code',
        },
    },
    halterConfig: {
        "halter_api_url": "https://api.dev.halter.io",
        "soothsayer_tools_api_secret": "1-fec57856dbb6c42eac2f9fb66621a79693a0b61c	",
        "soothsayer_staging_tools_api_secret": "1-aaa8a43f6647965123246fb562d532d12f42500d",
    },
};
export const staging = {
    awsConfig: {
        "aws_project_region": "ap-southeast-2",
        "aws_cognito_region": "ap-southeast-2",
        "aws_user_pools_id": "ap-southeast-2_QEQyfsj09",
        "aws_user_pools_web_client_id": "1818jp19ipr9h329iit047hf30",
        "oauth": {
            domain: 'auth.staging.halter.io',
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://farmhand.staging.halter.io',
            redirectSignOut: 'https://farmhand.staging.halter.io',
            responseType: 'code',
        },
    },
    halterConfig: {
        "halter_api_url": "https://api.staging.halter.io",
        "soothsayer_tools_api_secret": "1-fec57856dbb6c42eac2f9fb66621a79693a0b61c	",
        "soothsayer_staging_tools_api_secret": "1-aaa8a43f6647965123246fb562d532d12f42500d",
    },
};
export const prod = {
    awsConfig: {
        "aws_project_region": "ap-southeast-2",
        "aws_cognito_region": "ap-southeast-2",
        "aws_user_pools_id": "ap-southeast-2_sMhqq7MQr",
        "aws_user_pools_web_client_id": "5p2mlq10dt986f3pvh41ncfpp8",
        "oauth": {
            domain: 'auth.prod.halter.io',
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://farmhand.prod.halter.io',
            redirectSignOut: 'https://farmhand.prod.halter.io',
            responseType: 'code',
        },
    },
    halterConfig: {
        "halter_api_url": "https://api.prod.halter.io",
        "soothsayer_tools_api_secret": "1-fec57856dbb6c42eac2f9fb66621a79693a0b61c	",
        "soothsayer_staging_tools_api_secret": "1-aaa8a43f6647965123246fb562d532d12f42500d",
    },
};
export const environment = 'prod';
